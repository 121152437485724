<template>
  <img :src="mySVG" height="28" />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      mySVG: require("@/assets/images/logo/logo.svg"),
    };
  },
};
</script>
